import React from "react";
import { Route } from "react-router-dom";
import { LOGIN_URL } from "~/config";

/**
 * Component that protects route from unauthorized users.
 * @type {Function}
 */
export default ({ component: Component, ...rest }: { component: typeof React.Component }) => {
  if (!localStorage.getItem("user")) {
    return (location.href = "/login");
  }
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};
