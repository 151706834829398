import Auth from "./Auth/AuthView";
import asyncComponent from "../components/common/AsyncComponent";
import * as permissionType from "~/constants";

const AsyncBrief = asyncComponent(() => import("./Brief/BriefView"));
const AsyncBriefs = asyncComponent(() => import("./Briefs/BriefsView"));
const AsyncBriefNew = asyncComponent(() => import("./CreateBrief/CreateBrief"));
const AsyncBriefDraft = asyncComponent(() => import("./CreateDraftBrief/CreateDraftBrief"));
const AsyncBriefRebook = asyncComponent(() => import("./CreateRebook/CreateRebook"));
const AsyncClient = asyncComponent(() => import("./Client/ClientView"));
const AsyncClients = asyncComponent(() => import("./Clients/ClientsView"));
const AsyncClientNew = asyncComponent(() => import("./ClientNew/ClientNewView"));
const AsyncContact = asyncComponent(() => import("./Contact/ContactView"));
const AsyncConversations = asyncComponent(() => import("./Conversations/ConversationsView"));
const AsyncDashboard = asyncComponent(() => import("./Dashboard/DashboardView"));
const AsyncTalents = asyncComponent(() => import("./Talents/TalentsView"));
const AsyncTalent = asyncComponent(() => import("./Talent/TalentView"));
const AsyncTalentQuick = asyncComponent(() => import("./TalentQuick/TalentQuick"));
const AsyncNotifications = asyncComponent(() => import("./Notifications/NotificationsView"));
const AsyncNotificationPreferences = asyncComponent(() => import("./Notifications/NotificationPreferences"));
const AsyncSkills = asyncComponent(() => import("./Skills/SkillsView"));
const AsyncPermissions = asyncComponent(() => import("./UserManagement/UserManagementView"));
const AsyncBookings = asyncComponent(() => import("./Bookings/BookingsView"));
const AsyncSettings = asyncComponent(() => import("./Settings/SettingsView"));
const AsyncInvoices = asyncComponent(() => import("./Invoices/InvoicesView"));
const AsyncProfile = asyncComponent(() => import("./Profile/ProfileView"));
const AsyncPopupManagement = asyncComponent(() => import("./PopupManagement/PopupManagementView"));

export default () => {
  const routes = [
    {
      protected: true,
      component: AsyncDashboard,
      exact: true,
      path: "/",
    },
    {
      protected: true,
      component: AsyncBriefs,
      exact: true,
      path: "/briefs",
    },
    {
      protected: true,
      component: AsyncBriefNew,
      exact: true,
      path: "/briefs/new",
    },
    {
      protected: true,
      component: AsyncBriefDraft,
      exact: true,
      path: "/briefs/draft/:briefId",
    },
    {
      protected: true,
      component: AsyncBriefRebook,
      exact: true,
      path: "/briefs/direct_book",
    },
    {
      protected: true,
      component: AsyncBrief,
      path: "/briefs/:briefId",
    },
    {
      protected: true,
      component: AsyncClients,
      accessPermissions: [permissionType.accessAdmin],
      exact: true,
      path: "/clients",
    },
    {
      protected: true,
      accessPermissions: [permissionType.accessAdmin],
      component: AsyncClientNew,
      exact: true,
      path: "/clients/new",
    },
    {
      protected: true,
      accessPermissions: [permissionType.getOwnClientInfo, permissionType.getAnyClient],
      component: AsyncClient,
      path: "/clients/:clientId",
    },
    {
      protected: true,
      component: AsyncTalents,
      exact: true,
      path: "/talents",
    },
    {
      protected: true,
      component: AsyncTalentQuick,
      exact: true,
      path: "/talents/new",
    },
    {
      protected: true,
      component: AsyncTalent,
      path: "/talents/:talentId",
    },
    {
      protected: true,
      accessPermissions: [permissionType.accessClientAdmin],
      component: AsyncBookings,
      exact: true,
      path: "/bookings",
    },
    {
      protected: true,
      component: AsyncNotifications,
      path: "/notifications",
    },
    {
      protected: true,
      component: AsyncConversations,
      exact: true,
      path: "/conversations",
    },
    {
      protected: true,
      accessPermissions: [permissionType.accessAdmin],
      component: AsyncContact,
      exact: true,
      path: "/contact",
    },
    {
      protected: true,
      accessPermissions: [permissionType.accessAdmin],
      component: AsyncSkills,
      path: "/superpowers",
    },
    {
      protected: true,
      accessPermissions: [permissionType.accessAdmin],
      component: AsyncPermissions,
      path: "/permissions",
    },
    {
      protected: true,
      accessPermissions: [permissionType.accessAdmin],
      component: AsyncSettings,
      exact: true,
      path: "/settings",
    },
    {
      protected: true,
      accessPermissions: [permissionType.getOwnInvoice, permissionType.accessAdmin],
      component: AsyncInvoices,
      exact: true,
      path: "/invoices",
    },
    {
      protected: true,
      accessPermissions: [permissionType.accessClientAdmin],
      component: AsyncNotificationPreferences,
      exact: true,
      path: "/notification-preferences",
    },
    {
      protected: true,
      component: AsyncProfile,
      exact: true,
      path: "/profile",
    },
    {
      protected: true,
      component: AsyncPopupManagement,
      accessPermissions: [permissionType.accessAdmin],
      exact: true,
      path: "/popups",
    },
    {
      canAccess: true,
      component: Auth,
      exact: true,
      path: "/login",
    },
  ];

  return routes;
};
