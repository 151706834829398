import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../store/actions";
import "./LoginForm.scss";
import { AxiosError, AxiosResponse } from "axios";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import ThemedButton from "../../components/common/ThemedButton/ThemedButton";
import Loader from "../../components/Loader/Loader";
import { IState } from "../../store/reducers/index";
import { useFormik } from "formik";
import ApInputText from "../../components/common/ApInputText/ApInputText";
import { LoginSchema } from "../../schemas/user";

function LoginForm(props: RouteComponentProps) {
  const dispatch = useDispatch();

  const [error, setError] = useState("");
  const [animation, setAnimation] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { isAuthenticating, authenticatedUser } = useSelector((state: IState) => state.user);

  const talentLoginUrl = `https://${process.env.MESSENGER_URL || "lets.meetgenie.co"}/login`;

  const errors: { [k: string]: JSX.Element } = {
    WRONG_CREDENTIALS: (
      <>
        Computer says "no" - Is this the right email/pass combo?
        <br />
        <a href={talentLoginUrl}>If you are a talent trying to log in click here</a>
      </>
    ),
  };

  useEffect(() => {
    if (authenticatedUser?.token) {
      props.history.push("/");
    }
  }, [authenticatedUser?.token]);

  const savePermissions = (res: { data: { response: { user: { permissions: string } } } }) => {
    const { permissions } = res.data.response.user;
    localStorage.setItem("permissions", permissions);
  };
  const handleLogin = (form: { email: string; password: string }) => {
    dispatch(actions.user.loginUser(form))
      .then((res: AxiosResponse) => {
        if (res?.status === 200) {
          savePermissions(res);
          props.history.push("/");
          dispatch(actions.user.verifyToken());
        }
      })
      .catch((err: AxiosError) => {
        setError(err?.response?.data?.result || "Unknown error");
        setAnimation(true);
      });
  };

  const changeShowPassword = () => setShowPassword((prevState) => !prevState);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: handleLogin,
  });

  return (
    <form className="LoginForm form" onSubmit={formik.handleSubmit}>
      <h1>Client Login</h1>
      <div className="ThemedInput">
        <ApInputText
          formik={formik}
          id="email"
          noField
          placeholder="Email"
          isError={formik.touched.hasOwnProperty("email") && formik.errors.hasOwnProperty("email")}
        />
      </div>
      <div className="ThemedInput">
        <ApInputText
          formik={formik}
          id="password"
          noField
          placeholder="Password"
          type={showPassword ? "text" : "password"}
          isError={formik.touched.hasOwnProperty("password") && formik.errors.hasOwnProperty("password")}
          rightElement={
            <button
              className="eye"
              type="button"
              onTouchStart={changeShowPassword}
              onTouchEnd={changeShowPassword}
              onMouseDown={changeShowPassword}
              onMouseUp={changeShowPassword}
              onBlur={() => setShowPassword(false)}
            >
              <i className="icon-eye" />
            </button>
          }
        />
      </div>
      <ThemedButton className="submit" disabled={isAuthenticating || (formik.touched && !formik.isValid)} type="submit">
        {isAuthenticating ? <Loader /> : "Sign In"}
      </ThemedButton>
      {error && <span className="error-msg">{errors[error] || "Unknown error occurred."}</span>}
      <Link to="/reset_password">Forgot Password?</Link>
      <a
        className={animation ? "animation" : undefined}
        href={talentLoginUrl}
        onAnimationStart={() => setAnimation(true)}
        onAnimationEnd={() => setAnimation(false)}
      >
        Talent user trying to login? Click here!
      </a>
    </form>
  );
}

export default withRouter(LoginForm);
