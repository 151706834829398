import React from "react";
import "./ThemedButton.scss";

interface Props {
  children: JSX.Element | string;
  className?: string;
  disabled?: boolean;
  onClick?: any;
  type?: "button" | "submit" | "reset";
}

export default (props: Props) => {
  return (
    <button
      className={props.className || "button is-primary"}
      disabled={props.disabled || false}
      type={props.type || "button"}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};
